import React from 'react'
import { FaCirclePlay } from "react-icons/fa6";
import { AiOutlineStar } from "react-icons/ai";
import AK47 from '../Components/Images/AK 47.webp'
import updown from '../Components/Images/7 up 7 Down.webp'
import card from '../Components/Images/4 Card.webp'

export const More = () => {
  const handleDownload = () => {
    // Replace 'yourfile.apk' with the name of your APK file
    const apkUrl = process.env.PUBLIC_URL + '/TeenpattiUltra.apk';
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'TeenpattiUltra.apk'; // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  return (
    <div style={{height:'60vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
      <div>
          <img src={AK47} alt='' style={{width:'100%',height:'12vh'}}/>
      </div>
      <div style={{width:'30%'}}>
         <div style={{lineHeight:'0px'}}>
         <h4>AK 47</h4>
         <p style={{color:'gold'}}>
         <AiOutlineStar />
         <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
         </p>
         </div>
      </div>
      <div>
          <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownload} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
      </div>
    </div>
    <div style={{borderBottom:'1px solid #0096fc',height:'1px',width:'85%'}}></div>
    <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
      <div>
          <img src={updown} alt='' style={{width:'100%',height:'12vh'}}/>
      </div>
      <div style={{width:'30%'}}>
         <div style={{lineHeight:'12px'}}>
         <h4>7 up 7 Down</h4>
         <p style={{color:'gold',lineHeight:'0px'}}>
         <AiOutlineStar />
         <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
         </p>
         </div>
      </div>
      <div>
          <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownload} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
      </div>
     
    </div>
    <div style={{borderBottom:'1px solid #0096fc',height:'1px',width:'85%'}}></div>
    <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
      <div>
          <img src={card} alt='' style={{width:'100%',height:'12vh'}}/>
      </div>
      <div style={{width:'30%'}}>
         <div style={{lineHeight:'0px'}}>
         <h4>4 Card</h4>
         <p style={{color:'gold'}}>
         <AiOutlineStar />
         <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
         </p>
         </div>
      </div>
      <div>
          <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownload} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
      </div>
    </div>
   </div>
  )
}
