import React from 'react'

export const AboutUs = () => {
  return (
    <>
   <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'10vh',fontWeight:'bold'}}>
      <h1>Teen Patti Ultra</h1>
    </div>
    <div style={{fontWeight:'bold',fontSize:'20px'}}>
        About Us
      <ul>
        <li>Teen Patti Ultra is the ultimate Casino game app for playing all the variants of Casino online. Experience the thrill of gaming in the unique, enhanced gaming environment at 2D and 3D Teen Patti Ultra tables with royal player avatars, and challenge players from across the country. We offer you great opportunities to utilize your Casino skills and win real cash and other exciting prizes.</li>
        <li>This popular game of skill is completely legal and has been expressly validated by the Hon'ble Supreme Court of India. Playing Casino requires skills like decision making, quick reaction, good observation, as well as a sharp memory.</li>
        <li>You can play our Casino games 24x7 from your home, an Internet cafe or on any mobile device that has access to the Internet. You can create an account on our website and play free Casino games. With a vast experience of managing online multiplayer games, we know how important it is to maintain the complete confidentiality of your information and we assure you of 100% confidentiality. So open an account with us now, join one of our tables and start playing thrilling online Casino games.</li>
        <li>We constantly strive to make Teen Patti Ultra more and more thrilling and enjoyable for the community of online players. Our online card games are designed to provide you with an excellent opportunity to polish your skills in Casino games. With the increasing number of players registering and playing each day, we are the fastest-growing Casino game app in the country</li>
        <li>You may have played Casino on other games sites before, but the experience of playing on Teen Patti Ultra is absolutely unique and incomparably thrilling - we are simply a class apart....</li>
      </ul>
    </div>
    </>
 
  )
}
