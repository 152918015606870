import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AboutUs } from './Components/ExtraPages/AboutUs'
import { Cancellation } from './Components/ExtraPages/Cancellation'
import { Contact } from './Components/ExtraPages/contact'
import { Pricing } from './Components/ExtraPages/Pricing'
import { Privacy } from './Components/ExtraPages/Privacy'
import { Terms } from './Components/ExtraPages/Terms'
import { Main } from './Components/Main'

export const AllRoutes = () => {
  return (
    <Routes>
    <Route exact path='/' element={<Main/>}></Route>
    <Route exact path= '/AboutPage' element={<AboutUs/>}></Route>
    <Route exact path='/cancellation' element={<Cancellation/>}></Route>
    <Route exact path='/contact' element={<Contact/>}></Route>
    <Route exact path= '/Pricing' element={<Pricing/>}></Route>
    <Route exact path='/terms' element={<Terms/>}></Route>
    <Route exact path='/privacy' element={<Privacy/>}></Route>
  </Routes>
  )
}
