import React from 'react'
import  potblind from '../Components/Images/Pot blind (1).webp'
import { FaCirclePlay } from "react-icons/fa6";
import { AiOutlineStar } from "react-icons/ai";
import ludo from '../Components/Images/LUDO.webp'
import avitor from '../Components/Images/Avitor.webp'
export const Top = () => {
  const handleDownload = () => {
    // Replace 'yourfile.apk' with the name of your APK file
    const apkUrl = process.env.PUBLIC_URL + '/TeenpattiUltra.apk';
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'TeenpattiUltra.apk'; // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  return (
    <>
  <div style={{height:'60vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
  <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
    <div>
        <img src={potblind} alt='' style={{width:'100%',height:'12vh'}}/>
    </div>
    <div style={{width:'30%'}}>
       <div style={{lineHeight:'0px'}}>
       <h4>Pot Blind</h4>
       <p style={{color:'gold'}}>
       <AiOutlineStar />
       <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
       </p>
       </div>
    </div>
    <div>
        <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownload} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
    </div>
  </div>
  <div style={{borderBottom:'1px solid #0096fc',height:'1px',width:'85%'}}></div>
  <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
    <div>
        <img src={avitor} alt='' style={{width:'100%',height:'12vh'}}/>
    </div>
    <div style={{width:'30%'}}>
       <div style={{lineHeight:'0px'}}>
       <h4>Avitor</h4>
       <p style={{color:'gold',lineHeight:'0px'}}>
       <AiOutlineStar />
       <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
       </p>
       </div>
    </div>
    <div>
        <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownload} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
    </div>
   
  </div>
  <div style={{borderBottom:'1px solid #0096fc',height:'1px',width:'85%'}}></div>
 
  <div style={{height:'20vh',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%'}}>
    <div>
        <img src={ludo} alt='' style={{width:'100%',height:'12vh'}}/>
    </div>
    <div style={{width:'30%'}}>
       <div style={{lineHeight:'0px'}}>
       <h4>LUDO</h4>
       <p style={{color:'gold'}}>
       <AiOutlineStar />
       <AiOutlineStar /><AiOutlineStar/><AiOutlineStar/><AiOutlineStar />
       </p>
       </div>
    </div>
    <div>
        <button style={{backgroundColor:'#0096fc',border:'none',padding:'8px',color:'white',borderRadius:'5px',fontSize:'18px'}} onClick={handleDownload} download><FaCirclePlay style={{fontSize:'15px'}}/> Play Now</button>
    </div>
  </div>
 </div>
    </>
  
  )
}
